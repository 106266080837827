// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps, GameMode } from '../../global.d';
import { isFreeSpinsMode, updateCoinValueAfterBonuses } from '../../utils';
import Character from '../character/Character';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import WayIndicatorBase from '../indicator/Base';
import LaTomatina from '../laTomatina/LaTomatina';
import ltfLogo from '../laTomatina/lftLogo';
import ltfGun from '../laTomatina/ltfGun';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import PayLineEffect from '../payLineEffect/payLineEffect';
import ReelsFrame from '../reels/frame/reelsFrame';
import CountDownText from '../tomatoAndSpin/countDownText';
import TomatoContainer from '../tomatoFeature/tomatoContainer';
import TrackContainer from '../trackFeature/truckContainer';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: GameReplay;

  public coinsAnimationContainer: PIXI.Container;

  public frame: ReelsFrame;

  private messageWinBanner: MessageWinBanner | undefined;

  public payLineEffect: PayLineEffect;

  private wayIndicatorBase: WayIndicatorBase;

  private tomatoContainer: TomatoContainer;

  private trackContainer: TrackContainer;

  private laTomatina: LaTomatina;

  private character: Character;

  private lftLogo: ltfLogo;

  private lftGun: ltfGun;

  private countDownText: CountDownText;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.tomatoContainer = new TomatoContainer();
    this.trackContainer = new TrackContainer();
    this.laTomatina = new LaTomatina();
    this.payLineEffect = new PayLineEffect();
    this.wayIndicatorBase = new WayIndicatorBase();
    this.character = new Character();
    this.lftLogo = new ltfLogo();
    this.lftGun = new ltfGun();
    this.countDownText = new CountDownText();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.frame = new ReelsFrame();
    this.addChild(this.character);
    this.slotsContainer.addChild(this.tintContainer);
    this.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.slotsContainer);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.trackContainer);
    this.addChild(this.laTomatina);
    this.addChild(this.payLineEffect);
    this.addChild(this.frame);
    this.addChild(this.lftGun);
    this.addChild(this.lftLogo);
    this.addChild(this.wayIndicatorBase);
    this.addChild(this.gameTitle);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.tomatoContainer);
    this.addChild(this.countDownText);
    this.addChild(this.gameReplay);

    this.trackContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.trackContainer.addChild(this.trackContainer.mask);

    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));
  }

  private changeMode(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPIN_LA_TOMATINA) {
      this.slotsContainer.visible = false;
    } else {
      this.slotsContainer.visible = true;
    }
    if (isFreeSpinsMode(settings.mode)) {
      //
    } else {
      updateCoinValueAfterBonuses();
    }
  }

  private removeFreeSpinsTitle(): void {}

  private createFreeSpinsTitle(_props: FreeSpinsTitleProps): void {}

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
