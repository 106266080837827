import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { conversionPhoenixAnimationType, getAnimationSelectionTableLot } from '../anticipation';
import { baseGamePhoenix, phoenixAnimationType } from '../anticipation/table';
import { ISongs, SlotId, mappedAudioSprites } from '../config';
import {
  BetBonusReward,
  BetReward,
  EventTypes,
  GameMode,
  IFeatures,
  ISettledBet,
  MessageBannerProps,
  ReelId,
  ReelSet,
  UserBonus,
  bonusIds,
  freeRoundBonusId,
  reelSets,
} from '../global.d';
import {
  SetIsCountUp,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setDelayFeatureNum,
  setDelayWinAnimation,
  setDummyReelSet,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeaturePurchased,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFadeOut,
  setIsForceStop,
  setIsFreeSpinsWin,
  setIsLaTomatinaFeature,
  setIsLatomatina,
  setIsNotBlank,
  setIsOpenedMessageBanner,
  setIsPhoenix,
  setIsPortrait,
  setIsResumeInFreeSpin,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setIsTomatoAndSpinFeature,
  setLastRegularWinAmount,
  setLatomatina,
  setLatomatinaFeature,
  setMultiplier,
  setNextResult,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setReplayBet,
  setReplayTotalRounds,
  setSlotConfig,
  setStressful,
  setUserBalance,
  setUserLastBetResult,
  setWildAmount,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig, ISlotHistoryData } from '../gql/d';
import { ReelSetType, getUserBonuses, isStoppedGql, slotBetGql, slotHistoryGql } from '../gql/query';
import {
  getGameModeByBonusId,
  getScatterCount,
  getSpinResult,
  isBaseReelSet,
  isBuyFeatureMode,
  isFreeSpinsMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop2 from './backdrop2/backdrop2';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from './background/config';
import BgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import ViewContainer from './components/container';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_END_SYMBOLS_AMOUNT,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  FREE_SPINS_TIME_OUT_BANNER,
  MESSAGE_BANNER_LAYER,
  REELS_AMOUNT,
  REEL_HEIGHT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SlotMachineState,
  eventManager,
} from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import InfoBtn from './controlButtons/infoBtn';
import MenuBtn from './controlButtons/menuBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import WayIndicatorIcon from './indicator/wayIndicatorIcon';
import { MAX_WIN } from './messageBanner/config';
import MessageBanner from './messageBanner/messageBanner';
import { MessageWinBanner } from './messageBanner/messageWinBanner';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import SlotsAnimationContainer from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public menuBtn: MenuBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBtn: InfoBtn;

  public static initSlotMachine = (
    slotConfig: ISlotConfig,
    application: PIXI.Application,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(slotConfig, application, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public infoBuyFeatureIcon: PIXI.Container;

  public infoWayIndicatorIcon: PIXI.Container;

  private slotsAnimationContainer: SlotsAnimationContainer;

  private backdrop: ViewContainer;

  public width: number;

  public height: number;

  private constructor(
    slotConfig: ISlotConfig,
    application: PIXI.Application,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));

    let startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition);

    let reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT && reelSet.id === ReelId.REGULAR)!;

    setReelSetId(reelSet!.id);

    if (setUserLastBetResult().id) {
      let isBlank = false;
      let roundsLeft = 0;
      let totalRounds = 0;
      switch (getGameModeByBonusId(setCurrentBonus().bonusId)) {
        case GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || GameMode.BUY_FEATURE_TOMATO_SPIN:
          roundsLeft = setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore
            ? 3 - setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore.roundsLeft
            : 0;
          totalRounds = setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore
            ? setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore.currentRound
            : 0;
          startPosition = [1, 1, 1, 1, 1, 1];
          isBlank = true;
          break;

        case GameMode.FREE_SPIN_TOMATO_SPIN_SPIN:
          roundsLeft = setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureStore.round
            ? setUserLastBetResult().data.features.gameRoundStore.tomatoSpinFeatureStore.round
            : 0;

          totalRounds = roundsLeft;
          if (roundsLeft === 0) {
            setUserLastBetResult({
              ...setUserLastBetResult(),
              reelSetId: ReelId.REGULAR,
            });
          }
          break;

        default:
          roundsLeft = 0;
          break;
      }

      if (setReplayBet() != '') {
        if (roundsLeft > 0) roundsLeft -= 1;
      }

      setCurrentBonus({
        ...setCurrentBonus(),
        currentRound: roundsLeft,
        totalRounds: totalRounds,
      });
      if (roundsLeft === 0 && getGameModeByBonusId(setCurrentBonus().bonusId) !== GameMode.REGULAR) {
        eventManager.emit(EventTypes.MAKE_DUMMY_REEL, setUserLastBetResult().reelSetId, startPosition, isBlank);
      } else {
        eventManager.emit(
          EventTypes.MAKE_DUMMY_REEL,
          setUserLastBetResult().reelSetId,
          startPosition,
          isBlank,
          setUserLastBetResult().data.features,
        );
      }

      if (setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers) {
        setMultiplier([
          setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers.arrows.O.count,
          setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers.arrows.P.count,
          setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers.arrows.Q.count,
          setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers.arrows.R.count,
        ]);
        setWildAmount(setUserLastBetResult().data.features.gameRoundStore.arrowMultipliers.arrows.WL.count);
      }
    }

    this.reelsContainer = new ReelsContainer(
      setUserLastBetResult().id ? setDummyReelSet().layout : reelSet!.layout,
      startPosition,
    );
    this.tintContainer = new TintContainer();

    if (setLatomatina()) {
      startPosition = [0, 0, 0, 0, 0, 0];
      setUserLastBetResult({ ...setUserLastBetResult(), id: '' });
      eventManager.emit(EventTypes.MAKE_DUMMY_REEL, ReelId.REGULAR, startPosition, false);
      setReelSetId(ReelId.REGULAR);
      setPrevReelsPosition(startPosition);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setDummyReelSet(),
        reelPositions: startPosition,
      });
      reelSet = slotConfig.reels.find(
        (reelSet) => reelSet.type === ReelSetType.DEFAULT && reelSet.id === ReelId.REGULAR,
      )!;
      setMultiplier([1, 0, 0, 0]);
    }

    const spinResult = getSpinResult({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet: setUserLastBetResult().id ? setDummyReelSet() : reelSet!,
      icons: slotConfig.icons,
    });

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.sortableChildren = true;
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();

    this.slotsAnimationContainer = new SlotsAnimationContainer();
    this.width = BACKGROUND_SIZE_WIDTH;
    this.height = BACKGROUND_SIZE_HEIGHT;

    this.gameView = new GameView({
      winSlotsContainer: this.slotsAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.initBuyFeature(slotConfig.lines, this.gameView);
    this.spinBtn = new SpinBtn();

    this.menuBtn = new MenuBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.infoBtn = new InfoBtn();

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();
    this.infoWayIndicatorIcon = new WayIndicatorIcon();
    this.fadeArea = new FadeArea();
    this.phoenix = new Phoenix();

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.application.stage.addChild(this.background);
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    this.backdrop = new Backdrop2();
    this.application.stage.addChild(this.backdrop);

    this.application.stage.addChild(this.bottom);

    this.application.stage.addChild(
      this.menuBtn,
      this.turboSpinBtn,
      this.spinBtn,
      this.betBtn,
      this.autoplayBtn,
      this.infoBtn,
    );
    this.application.stage.addChildAt(this.fadeArea, 10);

    if (setBrokenBuyFeature()) {
      const currentBonus = setCurrentBonus();
      if (currentBonus.gameMode) {
        setIsFreeSpinsWin(true);
        setGameMode(currentBonus.gameMode);
        setBrokenBuyFeature(false);

        eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
          text: 'freeSpinsTitleText',
          spins: currentBonus.rounds,
          currentSpin: currentBonus.currentRound,
        });

        if (setCurrentFreeSpinsTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
        }

        eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, { mode: setGameMode() });

        if (currentBonus.totalRounds == 0 || currentBonus.totalRounds == undefined) {
          let title: 'laTomatinaTitle' | 'tomatoAndSpinTitle' | '';
          let title2: 'tomatoAndSpinTomatoTitle' | 'tomatoAndSpinSpinTitle' | undefined;
          let subTitle: 'laTomatinaText' | 'tomatoAndSpinText' | undefined;

          switch (setGameMode()) {
            case GameMode.FREE_SPIN_LA_TOMATINA || GameMode.BUY_FEATURE_LA_TOMATINA:
              title = 'laTomatinaTitle';
              title2 = undefined;
              subTitle = 'laTomatinaText';
              break;

            case GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || GameMode.BUY_FEATURE_TOMATO_SPIN:
              title = 'tomatoAndSpinTitle';
              title2 = 'tomatoAndSpinTomatoTitle';
              subTitle = 'tomatoAndSpinText';

              startPosition = [1, 1, 1, 1, 1, 1];

              eventManager.emit(EventTypes.RESET_ALL_MULTIPLIER);
              eventManager.emit(EventTypes.RESET_WILD);

              eventManager.emit(EventTypes.MAKE_DUMMY_REEL, ReelId.REGULAR, startPosition, true);
              const spinResult = getSpinResult({
                reelPositions: startPosition,
                reelSet: setDummyReelSet(),
                icons: setSlotConfig().icons,
              });
              setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));
              this.miniPayTableContainer.setSpinResult(spinResult);

              eventManager.emit(EventTypes.CHANGE_REEL_SET, {
                reelSet: setDummyReelSet(),
                reelPositions: startPosition,
              });
              eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
              break;

            case GameMode.FREE_SPIN_TOMATO_SPIN_SPIN:
              title = 'tomatoAndSpinTitle';
              title2 = 'tomatoAndSpinSpinTitle';
              subTitle = undefined;
              break;

            default:
              title = 'laTomatinaTitle';
              title2 = undefined;
              subTitle = undefined;
              break;
          }
          eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
            title: i18n.t(title),
            title2: title2 === undefined ? undefined : i18n.t(title2),
            subTitle: subTitle === undefined ? undefined : i18n.t(subTitle),
            btnText: i18n.t('startText'),
            callback: () => {
              this.setState(SlotMachineState.IDLE);
            },
            winTitle: '',
          });
        } else {
          if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
            setIsResumeInFreeSpin(true);
          }
          if (setGameMode() !== GameMode.BUY_FEATURE_LA_TOMATINA || setGameMode() !== GameMode.FREE_SPIN_LA_TOMATINA) {
            eventManager.emit(
              EventTypes.SET_COUNTDOWN,
              setCurrentBonus().rounds - setCurrentBonus().currentRound,
              false,
            );
          }
          this.setState(SlotMachineState.IDLE);
        }
      } else {
        setIsSlotBusy(true);
        eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
        nextTick(() => {
          eventManager.emit(EventTypes.SET_BROKEN_BUY_FEATURE, setIsSlotBusy());
          if (this.state === SlotMachineState.IDLE)
            eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, GameMode.FREE_SPIN_LA_TOMATINA);
        });
      }
    }

    this.application.stage.addChildAt(this.phoenix, 11);
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    this.application.stage.addChildAt(new MessageBanner(props), MESSAGE_BANNER_LAYER);
  }

  private createWinMessage(
    props: MessageBannerProps & {
      totalWin: string;
    },
  ): void {
    const totalWinDelay = Tween.createDelayAnimation(1000);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(250);
    });
    totalWinDelay.addOnComplete(() => {
      BgmControl.stopBgm();
      totalWinDelay.onSkip();
    });
    totalWinDelay.addOnSkip(() => {
      BgmControl.stopBgm();
    });
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
      AudioApi.play({ type: ISongs.TotalWinBanner_LTF, stopPrev: true });
    } else {
      AudioApi.play({ type: ISongs.TotalWinBanner_TSF, stopPrev: true });
    }
    totalWinDelay.start();
    this.application.stage.addChildAt(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
      MESSAGE_BANNER_LAYER,
    );
  }

  private initBuyFeature(lines: number[], view: GameView): void {
    view.addChild(new BuyFeatureBtn(), new Backdrop(), new BuyFeaturePopup(lines), new BuyFeaturePopupConfirm());
  }

  private onBrokenGame(): void {
    setCurrentFreeSpinsTotalWin(0);
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);

    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (
      isBaseReelSet(setUserLastBetResult().reelSetId) ||
      setUserLastBetResult().reelSetId === ReelId.BUY_FEATURE_LA_TOMATINA ||
      setUserLastBetResult().reelSetId === ReelId.BUY_FEATURE_TOMATO_SPIN
    ) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: i18n.t('laTomatinaTitle', { gameMode }),
        subTitle: i18n.t('laTomatinaText'),
        btnText: i18n.t('startText'),
        callback: () => {
          setCurrentBonus({
            ...setCurrentBonus(),
            isActive: true,
            totalRounds: setCurrentBonus().totalRounds,
            currentRound: 0,
          });
          this.setState(SlotMachineState.IDLE);
        },
        winTitle: '',
      });
    } else {
      if (setProgress().wasLoaded) {
        this.setState(SlotMachineState.IDLE);
      } else {
        eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
          this.setState(SlotMachineState.IDLE);
        });
      }
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      BgmControl.playBgmGameMode(setGameMode());
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.SFX_SM_CountUp_Loop });
      }
    });

    eventManager.addListener(EventTypes.FREE_SPIN, this.spin.bind(this));
    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
    eventManager.addListener(EventTypes.MAKE_DUMMY_REEL, this.makeDummyReel.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }) {
    const previousGameMode: number = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR && previousGameMode !== GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
        setReelSetId(settings.reelSetId);

        const spinResult = getSpinResult({
          reelPositions: settings.reelPositions.slice(0, REELS_AMOUNT),
          reelSet: setDummyReelSet(),
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, REELS_AMOUNT));
        this.miniPayTableContainer.setSpinResult(spinResult);

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setDummyReelSet(),
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
        setCurrentFreeSpinsTotalWin(0);
      } else if (settings.mode === GameMode.REGULAR && previousGameMode !== GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
        setCurrentFreeSpinsTotalWin(0);
      } else if (settings.mode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
        setReelSetId(settings.reelSetId);

        const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
        const spinResult = getSpinResult({
          reelPositions: settings.reelPositions.slice(0, REELS_AMOUNT),
          reelSet: reelSet!,
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, REELS_AMOUNT));
        this.miniPayTableContainer.setSpinResult(spinResult);

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: reelSet!,
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
      } else if (settings.mode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
        setReelSetId(settings.reelSetId);

        settings.reelPositions = [1, 1, 1, 1, 1, 1];
        eventManager.emit(EventTypes.RESET_ALL_MULTIPLIER);
        eventManager.emit(EventTypes.RESET_WILD);
        eventManager.emit(EventTypes.MAKE_DUMMY_REEL, settings.reelSetId, settings.reelPositions, true);

        const spinResult = getSpinResult({
          reelPositions: settings.reelPositions,
          reelSet: setDummyReelSet(),
          icons: setSlotConfig().icons,
        });
        setPrevReelsPosition(settings.reelPositions.slice(0, REELS_AMOUNT));
        this.miniPayTableContainer.setSpinResult(spinResult);

        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setDummyReelSet(),
          reelPositions: settings.reelPositions,
        });
        eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
      }
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency: showCurrency(setCurrency()),
        }),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = setCurrentBonus();
      if (!bonus) throw new Error('Something went wrong');

      setCurrentBonus({ ...bonus, totalRounds: 0 });
      if (previousGameMode !== currentGameMode) {
        eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
        eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
          text: 'freeSpinsTitleText',
          spins: bonus.rounds,
          currentSpin: 0,
        });
      } else {
        const levelUpChangesDelay = Tween.createDelayAnimation(150);
        levelUpChangesDelay.addOnComplete(() => {
          setCurrentBonus({
            ...bonus,
            isActive: true,
            currentRound: 0,
          });
          this.setState(SlotMachineState.IDLE);
        });
        levelUpChangesDelay.start();
      }

      if (previousGameMode !== currentGameMode) {
        let title: 'laTomatinaTitle' | 'tomatoAndSpinTitle';
        let title2: 'tomatoAndSpinTomatoTitle' | 'tomatoAndSpinSpinTitle' | undefined;
        let subTitle: 'laTomatinaText' | 'tomatoAndSpinText' | undefined;

        switch (setGameMode()) {
          case GameMode.FREE_SPIN_LA_TOMATINA:
            title = 'laTomatinaTitle';
            title2 = undefined;
            subTitle = 'laTomatinaText';
            break;

          case GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO:
            title = 'tomatoAndSpinTitle';
            title2 = 'tomatoAndSpinTomatoTitle';
            subTitle = 'tomatoAndSpinText';
            break;

          default:
            title = 'tomatoAndSpinTitle';
            title2 = 'tomatoAndSpinSpinTitle';
            subTitle = undefined;
            break;
        }

        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t(title),
          title2: title2 === undefined ? undefined : i18n.t(title2),
          subTitle: subTitle === undefined ? undefined : i18n.t(subTitle),
          btnText: i18n.t('startText'),
          callback: () => {
            setCurrentBonus({
              ...bonus,
              isActive: true,
              totalRounds: setCurrentBonus().totalRounds,
              currentRound: 0,
            });
            this.setState(SlotMachineState.IDLE);
          },
          winTitle: '',
        });
        this.setState(SlotMachineState.IDLE);

        if (setIsContinueAutoSpinsAfterFeature()) {
          const delay = Tween.createDelayAnimation(1000); // 5000
          delay.addOnComplete(() => {
            eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          });
          delay.start();

          setCurrentBonus({
            ...bonus,
            isActive: true,
            totalRounds: setCurrentBonus().totalRounds,
            currentRound: 0,
          });
        }
      }
    }
  }

  private startFreeSpins(userBonus: UserBonus): void {
    setIsFreeSpinsWin(true);
    const bonusId = setReplayBet() ? userBonus.bonusId : userBonus.bonus.id;
    const mode = getGameModeByBonusId(bonusId)!;
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: mode,
      reelPositions: this.nextResult?.bet.result.reelPositions!,
      reelSetId: reelSets[mode]!,
      fadeOutDuration: 1000,
      fadeInDuration: 1000,
    });
  }

  public async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const { betId } = res.data.userBonuses[0]!;

    let reelPositions: number[] = [0, 0, 0, 0, 0, 0];
    let reelSetId: string = ReelId.REGULAR;
    let features: IFeatures;

    if (betId === null) {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0] != undefined) {
        reelPositions = betsData.data.bets.edges[0].node.result.reelPositions;
        reelSetId = betsData.data.bets.edges[0].node.reelSetId;

        features = betsData.data.bets.edges[0].node.data.features;
      } else {
        features!.gameRoundStore.arrowMultipliers.arrows.O.count = 1;
        features!.gameRoundStore.arrowMultipliers.arrows.P.count = 0;
        features!.gameRoundStore.arrowMultipliers.arrows.Q.count = 0;
        features!.gameRoundStore.arrowMultipliers.arrows.R.count = 0;
      }
    } else {
      const bet = await client.query<ISettledBet>({
        query: slotBetGql,
        variables: { input: { id: betId } },
        fetchPolicy: 'network-only',
      });
      reelPositions = bet.data.bet.result.reelPositions;
      reelSetId = bet.data.bet.reelSetId;
      features = bet.data.bet.data.features;
    }
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
      reelPositions = [0, 0, 0, 0, 0, 0];
      reelSetId = ReelId.REGULAR;
      eventManager.emit(EventTypes.MAKE_DUMMY_REEL, reelSetId!, reelPositions!, false);
      setMultiplier([1, 0, 0, 0]);
    } else {
      eventManager.emit(EventTypes.MAKE_DUMMY_REEL, reelSetId!, reelPositions!, false, features!);
    }
    if (this.nextResult?.bet.data.features.gameRoundStore.arrowMultipliers) {
      setMultiplier([
        features!.gameRoundStore.arrowMultipliers.arrows.O.count,
        features!.gameRoundStore.arrowMultipliers.arrows.P.count,
        features!.gameRoundStore.arrowMultipliers.arrows.Q.count,
        features!.gameRoundStore.arrowMultipliers.arrows.R.count,
      ]);
    }

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    const callback = () => {
      this.isSpinInProgressCallback();
      const delayBalance = Tween.createDelayAnimation(1700);
      delayBalance.addOnComplete(() => {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      });
      delayBalance.start();
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
        fadeOutDuration: 1000,
        fadeInDuration: 1000,
      });
    };
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    setIsLatomatina(false);
    let freeSpinTotal: number | undefined = 0;
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
      freeSpinTotal = setCurrentFreeSpinsTotalWin();
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
      setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    } else {
      freeSpinTotal = setFreeSpinsTotalWin();
    }
    setLastRegularWinAmount(setFreeSpinsTotalWin());

    if (
      setIsResumeInFreeSpin() &&
      this.nextResult?.bet.data.features.gameRoundStore.tomatoSpinFeatureStore.sumPaylines === 100000
    ) {
      freeSpinTotal = setBetAmount() * MAX_WIN;
    }
    setIsResumeInFreeSpin(false);

    eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    const witTitle =
      freeSpinTotal! / setBetAmount() >= MAX_WIN
        ? i18n.t('freeSpinsTotalMaxWinTitle')
        : i18n.t('freeSpinsTotalWinTitle');

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        winTitle: witTitle,
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(freeSpinTotal),
          showCurrency: showCurrency(setCurrency()),
        })} `,
        totalSpins: setCurrentBonus().totalRounds,
        preventDefaultDestroy: true,
        callback,
        title: '',
        subTitle: '',
        btnText: '',
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        winTitle: witTitle,
        totalWin: `${formatNumber({
          currency: setCurrency(),
          value: normalizeCoins(freeSpinTotal),
          showCurrency: showCurrency(setCurrency()),
        })}`,
        totalSpins: setCurrentBonus().totalRounds,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: '',
        subTitle: '',
        btnText: '',
      });
    }

    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
      const delay = Tween.createDelayAnimation(1000);
      delay.addOnComplete(() => {
        this.setState(SlotMachineState.IDLE);
      });
      delay.start();
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(_total: number, _current: number): void {}

  public spin(isTurboSpin: boolean | undefined): void {
    if (setGameMode() === GameMode.REGULAR) setIsBuyFeaturePurchased(false);
    if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA && !SetIsCountUp()) {
      if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA && this.state === SlotMachineState.IDLE) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        bonus.totalRounds += 1;
        setCurrentBonus(bonus);
      }
      return;
    }
    this.reelsContainer.forcedStop = false;
    setIsForceStop(false);
    setDelayWinAnimation(0);
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isFreeSpinsMode(setGameMode()) || isBuyFeatureMode(setGameMode())) {
            this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
          }
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);

          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            getScatterCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            this.getAnticipationEndReelId(this.nextResult.bet.result.spinResult),
          );
          eventManager.emit(EventTypes.UPDATE_FREE_SPIN_ROUND);
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      // でっち上げリール作成
      setDelayFeatureNum(0);

      if (setGameMode() === GameMode.REGULAR) {
        eventManager.emit(EventTypes.RESET_ALL_MULTIPLIER);
      }

      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);

      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        if (
          setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA ||
          setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN ||
          setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO
        ) {
          bonus.currentRound += 1;
          bonus.totalRounds += 1;
        }
        setCurrentBonus(bonus);
        eventManager.emit(
          EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
          setCurrentBonus().rounds,
          setCurrentBonus().currentRound,
          false,
        );
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(setCurrentBonus().currentRound, setCurrentBonus().rounds);
            }

            this.dynamicReelSetChange(this.nextResult!.bet.reelSet.id);

            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
              this.getAnticipationEndReelId(this.nextResult.bet.result.spinResult),
            );
            eventManager.emit(EventTypes.UPDATE_FREE_SPIN_ROUND);

            const betAmount = normalizeCoins(setBetAmount());
            const winAmount = normalizeCoins(this.nextResult.bet.result.winCoinAmount);
            const multiplier = winAmount / betAmount;

            if (multiplier >= 200 || (setIsTomatoAndSpinFeature() && setGameMode() === GameMode.REGULAR)) {
              const AnimationPtn = conversionPhoenixAnimationType(
                getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix),
              );
              if (AnimationPtn === phoenixAnimationType.PHOENIX) {
                eventManager.emit(EventTypes.PHOENIX_START);
              }
            }
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonusId != freeRoundBonusId;
    })?.userBonus;
  }

  private getReplayBonusFromResult(): UserBonus | undefined {
    return this.nextResult?.bet.data.bonuses.find(
      // eslint-disable-next-line no-underscore-dangle
      (reward) =>
        reward.bonusId === bonusIds[GameMode.FREE_SPIN_LA_TOMATINA] ||
        reward.bonusId === bonusIds[GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO] ||
        reward.bonusId === bonusIds[GameMode.FREE_SPIN_TOMATO_SPIN_SPIN],
    ) as UserBonus;
  }

  public getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';
    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN' || reward.userBonus?.bonus.type === 'SPECIAL_ROUND';
    })?.userBonus;
  }

  private onCountUpEnd(): void {
    this.isSpinInProgressCallback();
    const bonus = this.getFreeSpinBonus();

    console.log('setCurrentBonus totalRound', setCurrentBonus().totalRounds, setCurrentBonus().currentRound);

    const replayBonus =
      setCurrentBonus().currentRound === 3 && setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN
        ? undefined
        : this.getReplayBonusFromResult();

    console.log('bonus', bonus);
    console.log('replayBonus', replayBonus);

    const bonusInfo = bonus != undefined ? bonus : replayBonus;

    const mode = setGameMode();
    if (bonusInfo) {
      if (mode === GameMode.REGULAR || isBuyFeatureMode(mode)) {
        setCurrentBonus({
          ...bonusInfo,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        this.startFreeSpins(bonusInfo);
        this.setState(SlotMachineState.IDLE);
      } else if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
        console.log('setCurrentBonus totalRound', setCurrentBonus().totalRounds, setCurrentBonus().currentRound);
        setReplayTotalRounds(setCurrentBonus().totalRounds);
        setCurrentBonus({
          ...bonusInfo,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
          rounds: 3,
        });
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        AudioApi.play({ type: ISongs.TSF_transition });
        this.startFreeSpins(bonusInfo);
      } else if (!setIsNotBlank()) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());

        this.setState(SlotMachineState.IDLE);
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        this.setState(SlotMachineState.IDLE);
      } else if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);

        if (mode === GameMode.FREE_SPIN_LA_TOMATINA) {
          this.endFreeSpins();
        } else {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
          this.setState(SlotMachineState.IDLE);
        }
      }
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);

    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO && setIsNotBlank()) {
      eventManager.emit(EventTypes.SET_COUNTDOWN, setCurrentBonus().rounds, true);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      const delay = Tween.createDelayAnimation(1000);
      delay.addOnComplete(() => {
        this.onRetrigger();
      });
      delay.start();
    }
  }

  private dynamicReelSetChange(reelId: string): void {
    if (setReelSetId() !== reelId && setGameMode() !== GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
      if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setDummyReelSet(),
          reelPositions: [0, 0, 0, 0, 0, 0],
        });
      } else {
        eventManager.emit(EventTypes.CHANGE_REEL_SET, {
          reelSet: setSlotConfig().reels.find((reels) => reels.id === reelId)!,
          reelPositions: [0, 0, 0, 0, 0, 0],
        });
      }
      setReelSetId(reelId);
    }
  }

  private onRetrigger(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO,
      reelPositions: [1, 1, 1, 1, 1, 1],
      reelSetId: setReelSetId(),
    });
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);

    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: setDummyReelSet(),
      reelPositions: this.nextResult!.bet.result.reelPositions,
    });
    setReelSetId(ReelSetType.TOMATO);

    const spinResult = getSpinResult({
      reelPositions: this.nextResult!.bet.result.reelPositions.slice(0, 6),
      reelSet: setDummyReelSet(),
      icons: setSlotConfig().icons,
    });
    setNextResult(setNextResult());

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    eventManager.emit(
      EventTypes.SETUP_REEL_POSITIONS,
      this.nextResult!.bet.result.reelPositions,
      getScatterCount(spinResult),
      this.getAnticipationStartReelId(spinResult),
      this.getAnticipationEndReelId(spinResult),
    );

    const addAnimationDelay = Tween.createDelayAnimation(1000);
    addAnimationDelay.addOnComplete(() => {
      this.miniPayTableContainer.setSpinResult(spinResult!);
    });
    addAnimationDelay.start();
  }

  private getAnticipationEndReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_END_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i]!.id === symbolId) currentCount++;
        }
        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId + 1;
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i]!.id === symbolId) currentCount++;
        }

        if (currentCount >= count!) minReelId = Math.min(minReelId, j);
      }
    });

    return minReelId;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    setIsNotBlank(false);
    if (result.bet.data.features.gameRoundStore.laTomatinaFeatureStore) {
      if (result.bet.data.features.gameRoundStore.laTomatinaFeatureStore.sumMultiplier > 1) {
        setLatomatinaFeature(result.bet.data.features.gameRoundStore.laTomatinaFeatureStore);
        eventManager.emit(EventTypes.LATOMATINA_START);
        setIsLatomatina(true);
      }
    }

    if (!setIsLatomatina()) {
      let isBlank = false;
      if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
        result.bet.result.reelPositions = [1, 1, 1, 1, 1, 1];
        isBlank = true;
      }
      eventManager.emit(
        EventTypes.MAKE_DUMMY_REEL,
        result.bet.reelSet.id,
        result.bet.result.reelPositions,
        isBlank,
        result.bet.data.features,
      );

      setMultiplier([
        result.bet.data.features.gameRoundStore.arrowMultipliers.arrows.O.count,
        result.bet.data.features.gameRoundStore.arrowMultipliers.arrows.P.count,
        result.bet.data.features.gameRoundStore.arrowMultipliers.arrows.Q.count,
        result.bet.data.features.gameRoundStore.arrowMultipliers.arrows.R.count,
      ]);

      setWildAmount(result.bet.data.features.gameRoundStore.arrowMultipliers.arrows.WL.count);

      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setDummyReelSet(),
        reelPositions: result.bet.result.reelPositions,
      });

      setReelSetId(ReelSetType.TOMATO);

      const spinResult = getSpinResult({
        reelPositions: result.bet.result.reelPositions.slice(0, 6),
        reelSet: setDummyReelSet(),
        icons: setSlotConfig().icons,
      });

      result.bet.result.spinResult = spinResult;
      this.nextResult = result;
      setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
      setNextResult(result);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
      setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    } else {
      this.nextResult = result;
      setNextResult(result);
      if (setBrokenBuyFeature()) {
        setBrokenBuyFeature(false);
      }
    }

    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO) {
      setCurrentBonus({
        ...setCurrentBonus(),
        currentRound:
          setCurrentBonus().rounds -
          result.bet.data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore?.roundsLeft,
      });

      if (this.getBonusFromResult()) {
        const bonus = this.getBonusFromResult();
        if (bonus!.id) {
          setCurrentBonus({
            ...setCurrentBonus(),
            id: bonus!.id,
          });
        }
      }
    }

    if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
      if (result.bet.data.features.gameRoundStore.tomatoSpinFeatureStore.sumPaylines === 100000) {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: 3,
        });
      }
    }
  }

  private makeDummyReel(reelSetId: string, reelPositions: number[], isBlank: boolean, features?: IFeatures) {
    const fakeAmount = 10;
    const blankReelLength = 50;
    if (features) {
      eventManager.emit(EventTypes.TOMATO, features);
    }

    const reelSet = setSlotConfig().reels.find((reels) => reels.id === reelSetId);

    // でっちあげ
    const reel0: SlotId[] = [];
    const reel1: SlotId[] = [];
    const reel2: SlotId[] = [];
    const reel3: SlotId[] = [];
    const reel4: SlotId[] = [];
    const reel5: SlotId[] = [];
    const reels: SlotId[][] = [reel0, reel1, reel2, reel3, reel4, reel5];
    if (isBlank) {
      reels.forEach((reel) => {
        for (let i = 0; i < blankReelLength; i++) {
          reel[i] = SlotId.v;
        }
      });
    } else {
      reels.forEach((_pos, index) => {
        reels[index] = reelSet!.layout[index]!.slice(0, reelSet?.layout[index]!.length)!;
      });
    }

    let countSc = 0;
    if (features?.gameRoundStore.outcomeGrid) {
      Object.entries(features.gameRoundStore.outcomeGrid).forEach(([index, value]) => {
        const order = Number(index);
        const row = order % REELS_AMOUNT;
        let column = reelPositions[row]! + Math.floor(Number(index) / REELS_AMOUNT) - 1;
        if (column < 0) column = reels[row]!.length - 1;
        reels[row]![column]! = value;
        if (!isBlank && value === SlotId.SC1) countSc++;
        if (isBlank && value !== SlotId.v) {
          setIsNotBlank(true);
          const positionX = row * REEL_WIDTH + REEL_WIDTH / 2;
          const positionY = Math.floor(Number(index) / REELS_AMOUNT) * REEL_HEIGHT + REEL_HEIGHT / 2;
          eventManager.once(EventTypes.TOMATO_DELETE, () => {
            eventManager.emit(EventTypes.PLAY_ANNOUNCE_LOOP, value, { x: positionX, y: positionY });
          });
        }
      });
    }

    if (isBlank) {
      const fakeIcons = [SlotId.WL, SlotId.O, SlotId.P, SlotId.Q, SlotId.R];

      for (let i = 0; i < fakeAmount; i++) {
        const row = Math.floor(Math.random() * REELS_AMOUNT);
        const column = Math.floor(Math.random() * (reels[row]!.length - 6) + 6);
        const icon = fakeIcons[Math.floor(Math.random() * fakeIcons.length)]!;
        reels[row]![column]! = icon;
      }
    } else {
      switch (countSc) {
        case 3:
          setIsLaTomatinaFeature(true);
          break;
        case 4:
          setIsTomatoAndSpinFeature(true);
          break;
        default:
          setIsLaTomatinaFeature(false);
          setIsTomatoAndSpinFeature(false);
          break;
      }
    }

    const dummyReelSet: ReelSet = {
      id: reelSetId,
      layout: reels,
      type: ReelSetType.FS_FAKE_REEL,
    };

    setDummyReelSet(dummyReelSet);
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    if (setBrokenBuyFeature()) {
      setBrokenBuyFeature(false);
    }
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    eventManager.emit(EventTypes.TOMATO_DELETE);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.width = width;
    this.height = height;
    if (width < height) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
    eventManager.emit(EventTypes.RESIZE_UI_BUTTON, width, height);
  }

  private setState(state: SlotMachineState): void {
    console.log('setGameMode() ', setGameMode());
    if (state === SlotMachineState.IDLE && this.state === SlotMachineState.IDLE && !isFreeSpinsMode(setGameMode())) {
      return;
    }

    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    return this.nextResult?.bet ? this.nextResult!.bet.result.winCoinAmount > 0 : false;
  }
  public hasWin2(): boolean {
    return this.nextResult!.paylines.find((payline) => payline.lineId >= 0) != undefined ? true : false;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature() || setIsFadeOut(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      console.log(
        'setCurrentBonus().rounds',
        setCurrentBonus().rounds,
        'setCurrentBonus().currentRound',
        setCurrentBonus().currentRound,
      );

      if (isFreeSpinsMode(setGameMode()) && !setIsOpenedMessageBanner()) {
        if (
          setGameMode() !== GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          console.log('onStatechange');
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          if (setGameMode() === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().totalRounds === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      const delay = Tween.createDelayAnimation(setDelayWinAnimation());
      delay.addOnComplete(() => {
        if (this.nextResult?.bet.data.features.gameRoundStore.tomatoSpinFeatureMultipliersStore) {
          //  TomatoSpin retrigger
          eventManager.emit(EventTypes.JINGLE_START);
          const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SFX_WIN_FeatureTrigger].duration);
          jingleDelay.addOnStart(() => {
            if (setIsNotBlank()) {
              AudioApi.play({ type: ISongs.FeatureReTrigger, stopPrev: true });
            }
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        } else if (setIsLaTomatinaFeature() || setIsTomatoAndSpinFeature()) {
          //  Latomatina
          eventManager.emit(EventTypes.JINGLE_START);
          const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SFX_WIN_FeatureTrigger].duration);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        } else {
          this.setState(SlotMachineState.WINNING);
        }
      });
      delay.start();

      eventManager.once(EventTypes.SET_IS_SLOTS_STOPPED, () => {
        delay.onSkip();
      });
    }
    if (state === SlotMachineState.WINNING) {
      setIsPhoenix(false);
      eventManager.emit(EventTypes.SPIN_END);
      if ((this.hasWin() && this.hasWin2()) || setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false, false, false);
      } else {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
