// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloQueryResult } from '@apollo/client';

import { BonusStatus, EventTypes, GameMode, UserBonus, bonusIds, freeRoundBonusId } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsLaTomatinaFeature,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotHistoryData } from '../../gql/d';
import { getUserBonuses, userBonusBetsGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { calcActiveUserBonusTotalWinAmount, getGameModeByBonusId } from '../../utils';

export const checkBrokenGame = async () => {
  const activeUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });

  // TODO(FRB) on loading
  // ->its locally for testing.
  /*  const originActiveUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const activeUserBonusData: ApolloQueryResult<{
    userBonuses: UserBonus[];
  }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

  activeUserBonusData.data.userBonuses.push({
    ...(setCurrentBonus() as UserBonus),
    id: freeRoundBonusId,
    bonusId: freeRoundBonusId,
    coinAmount: 1,
    coinValue: 100, // KRW
    //coinValue: 1, // EUR
    rounds: 2,
    totalWinAmount: 0,
    isActive: true,
    currentRound: 0,
  });
  */
  // <-its locally for testing.

  const fsBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId != freeRoundBonusId);

  if (activeUserBonusData.data.userBonuses.length > 0) {
    const gameMode = getGameModeByBonusId(activeUserBonusData.data.userBonuses[0]!.bonusId);
    if (gameMode === GameMode.FREE_SPIN_LA_TOMATINA) {
      setBrokenBuyFeature(true);
      if (activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA]) {
        setIsLaTomatinaFeature(true);
      }
      setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);

      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        gameMode,
      });
    } else if (gameMode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || gameMode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
      const freeSpinBonus = activeUserBonusData.data.userBonuses[0]!;
      setBrokenBuyFeature(true);
      setCurrentBonusId(freeSpinBonus.id);

      setCurrentBonus({
        ...freeSpinBonus,
        isActive: true,
        rounds: 3,
        currentRound: 0,
        gameMode,
      });

      const fsTotalAmount = freeSpinBonus.totalWinAmount ? freeSpinBonus.totalWinAmount / freeSpinBonus.coinValue : 0;
      setCurrentFreeSpinsTotalWin(fsTotalAmount);
    } else if (fsBonus != undefined) {
      setBrokenGame(true);
      // const fsTotalAmount = fsBonus?.totalWinAmount ? fsBonus?.totalWinAmount / fsBonus.coinValue : 0;
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        totalRounds: 0,
        gameMode,
      });
      setCurrentFreeSpinsTotalWin(0);
      const userBonusBetsData = await client.query<{
        bets: ISlotHistoryData;
      }>({
        query: userBonusBetsGql,

        variables: {
          input: {
            filter: {
              userBonusId: setCurrentBonus().id,
            },
          },
        },
        fetchPolicy: 'network-only',
      });

      setCurrentBonus({
        ...setCurrentBonus(),
        currentRound: userBonusBetsData.data.bets.pageInfo.count,
        rounds: 3,
        totalWinAmount: 0,
      });

      const totalWinAmount = calcActiveUserBonusTotalWinAmount(setCurrentBonus());
      if (totalWinAmount > 0) {
        // setCurrentFreeSpinsTotalWin2(totalWinAmount);
        eventManager.once(EventTypes.UPDATE_USER_BALANCE, () => {
          setTimeout(() => {
            eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
            // eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin2());
          });
        });
      }
    }

    const freeRoundBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId);
    if (freeRoundBonus) {
      if (!fsBonus || fsBonus.data.frbReferenceId) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
        setFreeRoundBonus({
          id: freeRoundBonus.id,
          bonusId: freeRoundBonus.bonusId,
          coinAmount: freeRoundBonus.coinAmount,
          coinValue: freeRoundBonus.coinValue,
          status: freeRoundBonus.status,
          rounds: freeRoundBonus.rounds,
          totalWinAmount: totalWinAmount,

          isActive: true,
          currentRound: 0,
        });
        setFreeRoundsTotalWin(totalWinAmount);
      }
    }
  }
};
