import { makeVar } from '@apollo/client';

import { getUserConfig } from '@phoenix7dev/utils-fe';

import { SlotId } from '../config';
import {
  BonusStatus,
  FeatureTypes,
  FreeRoundBonus,
  GameMode,
  IBonus,
  ILaTomatinaFeatureStore,
  ISettledBet,
  IUserBalance,
  PopupOpeningTypes,
  ReelAnticipation,
  ReelSet,
  TotalWinBannerMode,
  UserBonus,
} from '../global.d';

import { IBet, IConfig, ISlotConfig, IStressful, ReelSetType } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0, 0]);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsPortrait = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setIsForceStop = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(1);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IUserBalance>({
  balance: { amount: 0, currency: '' },
  clientId: '',
  id: '',
});

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeature = makeVar<boolean>(false);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinAmount = makeVar<number>(1);

export const setCoinValue = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.REGULAR);

export const setReelSetId = makeVar<string>('');

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setCurrentBonusId = makeVar<string>('');

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  data: {
    features: {
      gameRoundStore: {
        arrowMultipliers: {
          arrows: {
            O: {
              count: 1,
              matrixIndexes: [],
            },
            P: {
              count: 0,
              matrixIndexes: [],
            },
            Q: {
              count: 0,
              matrixIndexes: [],
            },
            R: {
              count: 0,
              matrixIndexes: [],
            },
            WL: {
              count: 0,
              matrixIndexes: [],
            },
          },
        },
        laTomatinaFeatureStore: {
          multipliers: [],
          sumMultiplier: 0,
        },
        mysteryTruckFeature: {
          mappedIcons: [],
        },
        outcomeGrid: [],
        tomatoFeatureStore: {
          mappedIcons: [],
        },
        tomatoSpinFeatureMultipliersStore: {
          currentRound: 0,
          roundsLeft: 0,
        },
        tomatoSpinFeatureStore: {
          mode: '0',
          round: 0,
          sumPaylines: 0,
        },
      },
    },
  },
  result: {
    reelPositions: [],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
  userBonus: {
    bonusId: '',
    betId: '',
    bonus: {
      coinAmount: 0,
    },
  },
});

export const setCurrentBonus = makeVar<UserBonus>({
  bonusId: '',
  id: '',
  gameMode: GameMode.REGULAR,
  totalRounds: 0,
  betId: '',
  rounds: 0,
  reelSetId: '',
  bonus: {
    id: '',
    slotId: '',
    reelSetId: '',
    lineSetId: '',
    type: '',
    coinAmount: 1,
    purchasable: false,
    rounds: 0,
    data: {},
  },
  totalWinAmount: 0,
  coinValue: 0,
  coinAmount: 0,
  data: {
    count: 0,
    creditMultiplier: 0,
    debitMultiplier: 0,
    initialRoundId: '',
    maxRound: 0,
    frbReferenceId: null,
  },
  status: BonusStatus.INACTIVE,
  isActive: false,
  currentRound: 0,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    slotId: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  settings: {
    betLines: {
      min: 0,
      max: 0,
    },
    startPosition: [0, 0, 0, 0, 0, 0],
    playerSettings: {
      betAmount: {
        min: 0,
        max: 0,
      },
      coinValue: {
        EUR: {
          min: 0,
          max: 0,
        },
        FUN: {
          min: 0,
          max: 0,
        },
      },
    },
  },
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  winLines: [],
  isBuyFeatureEnabled: true,
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setIsEnabledSpaceSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isEnabledSpaceSpin', true));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsOpenMenuModal = makeVar<{
  isOpen: boolean;
  type: 'menu' | 'info';
}>({
  isOpen: false,
  type: 'menu',
});

export const setIsOpenBetSettingsModal = makeVar<boolean>(false);
export const setIsOpenAutoplayModal = makeVar<boolean>(false);

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setIsOpenedMessageBanner = makeVar<boolean>(false);

export const setIsTotalWinMessageBanner = makeVar<TotalWinBannerMode>(TotalWinBannerMode.NON);

export const setIsInTransition = makeVar<boolean>(false);

export const setTomatoAndSpins = makeVar<boolean>(false);

export const setIsLaTomatinaFeature = makeVar<boolean>(false);

export const setIsTomatoAndSpinFeature = makeVar<boolean>(false);

export const setFeatureType = makeVar<FeatureTypes>(FeatureTypes.LA_TOMATINA);

export const setReelAnticipation = makeVar<ReelAnticipation>(ReelAnticipation.NON);

export const setIsAnticipation = makeVar<boolean>(false);

export const setAnticipationLine = makeVar<number[]>([0]);

export const setAnticipationSlotId = makeVar<SlotId[]>([]);

export const setBrokenGameReelSet = makeVar<string>('');

export const setStopReel = makeVar<{
  reelPos: number;
  time: number;
}>({
  reelPos: 0,
  time: 0,
});

export const setCurrentStage = makeVar<number>(0);

export const SetIsCountUp = makeVar<boolean>(false);

export const setIsLatomatina = makeVar<boolean>(false);

export const setIsHighLightSlots = makeVar<boolean>(false);

export const setHighLightSlotPos = makeVar<number[]>([]);

export const setIsFreeSpinModeSceneChange = makeVar<boolean>(false);

export const setIsPopupOpened = makeVar<boolean>(false);
export const setIsOpenInfoPopup = makeVar<boolean>(false);
export const setIsOpenHistoryPopup = makeVar<boolean>(false);
export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);
export const setIsOpenAutoplayPopup = makeVar<boolean>(false);
export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);
export const setIsFadeOut = makeVar<boolean>(false);

export const setMultiplier = makeVar<number[]>([1, 0, 0, 0]);
export const setWildAmount = makeVar<number>(0);

export const setDummyReelSet = makeVar<ReelSet>({
  id: '',
  layout: [],
  type: ReelSetType.DEFAULT,
});

export const setDelayFeatureNum = makeVar<number>(0);
export const setDelayWinAnimation = makeVar<number>(0);
export const setIsBuyFeaturePurchased = makeVar<boolean>(false);
export const setIsTimeoutErrorMessage = makeVar(false);

export const setReplayBet = makeVar<string>('');

export const setReplayFreeSpinBets = makeVar<string[]>([]);

export const setReplayTotalRounds = makeVar<number>(0);

export const setReplayFreeSpinReelSetId = makeVar<string>('');

export const setReplayFreeSpinPlayedRounds = makeVar<number>(0);

export const setIsNotBlank = makeVar<boolean>(false);

export const setLatomatinaFeature = makeVar<ILaTomatinaFeatureStore>({
  multipliers: [],
  sumMultiplier: 0,
});

export const setLatomatina = makeVar<boolean>(false);
export const setIsPhoenix = makeVar<boolean>(false);

export const setIsResumeInFreeSpin = makeVar<boolean>(false);

export const setFreeRoundBonus = makeVar<FreeRoundBonus>({
  id: '',
  isActive: false,
  coinAmount: 1,
  coinValue: 1,
  bonusId: '',
  //roundsPlayed: 0,
  status: BonusStatus.INACTIVE,
  currentRound: 0,
  rounds: 1,
  totalWinAmount: 0,
});

export const setIsFreeRoundBonus = makeVar<boolean>(false);
export const setIsPopupFreeRoundsOpened = makeVar<boolean>(false);
export const setFreeRoundsTotalWin = makeVar<number>(0);
export const setSlotMachineInitialized = makeVar<boolean>(false);
