import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { filterMouseOver, isFreeSpinsMode, updateTextScale } from '../../utils';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from '../background/config';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_PORTRAIT_HEIGHT,
  FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN,
  FEATURE_BTN_PORTRAIT_WIDTH,
  GAME_CONTAINER_WIDTH,
  REEL_HEIGHT,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

import { textPositionY } from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  private windowWidth: number;

  private windowHeight: number;

  private filterHover = new PIXI.filters.ColorMatrixFilter();

  private filterGrayscale = new PIXI.filters.ColorMatrixFilter();

  private isFreeRoundBonus: boolean;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);

    this.isFreeRoundBonus = false;

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.handleDisable(isFadeOut);
    });

    eventManager.addListener(EventTypes.SET_BROKEN_BUY_FEATURE, (isBroken: boolean) => {
      this.handleDisable(isBroken);
    });

    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });

    this.filterHover.saturate(1, true);
    this.filterGrayscale.grayscale(0.5, true);
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.anchor.set(0.5, 0.5);
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.1, 1.1);
        this.text.style = buyFeatureHoverTextStyle;
        // btn.filters = [this.filterHover];
        this.btn.filters = [filterMouseOver()];
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.0, 1.0);
        btn.filters = null;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureClickTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(0.9, 0.9);
        btn.filters = [this.filterHover];
        // this.btn.filters = [new ColorOverlayFilter([1, 0.84, 0.0], 0.8)];
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.scale.set(1.0, 1.0);
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t('buyFeatureBtn'), buyFeatureTextStyle);

    text.position.y = textPositionY;
    text.anchor.set(0.5);
    updateTextScale(text, 120, 100);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      AudioApi.play({ type: ISongs.SFX_BuyFeature });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    updateTextScale(this.text, 120, 100);

    if (this.isAutoSpinInProgress) {
      this.btn.buttonMode = false;
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }

      return;
    }

    this.isDisabled = disable;

    if (disable) {
      this.btn.buttonMode = false;
      this.btn.filters = [this.filterGrayscale];
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
    } else {
      this.btn.buttonMode = true;
      this.btn.filters = null;
      this.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    if (width > height) {
      this.btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
      this.btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    } else {
      this.btn.width = FEATURE_BTN_PORTRAIT_WIDTH;
      this.btn.height = FEATURE_BTN_PORTRAIT_HEIGHT;
    }

    this.setPosition(width, height);

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    if (width > height) {
      this.x = 15 - FEATURE_BTN_LANDSCAPE_WIDTH;
      this.y = REEL_HEIGHT - 100;
    } else {
      this.x = GAME_CONTAINER_WIDTH / 2 - this.btn.width / 2 + FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN;
      this.y = SLOTS_CONTAINER_HEIGHT + 130;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
