import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setCurrentFreeSpinsTotalWin, setGameMode, setIsFadeOut } from '../../gql/cache';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { FREE_SPINS_FADE_DURATION, eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
    eventManager.addListener(
      EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS,
      this.startFreeRoundsEndFade.bind(this),
    );
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: { mode: GameMode; reelPositions: number[]; reelSetId: string }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_DURATION / 2);
    animationChain.addOnStart(() => {
      setIsFadeOut(true);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, true);
    });
    animationChain.addOnComplete(() => {
      setIsFadeOut(false);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, false);
    });
    fadeOut.addOnComplete(() => {
      if (settings.mode === GameMode.REGULAR) {
        if (setGameMode() === GameMode.FREE_SPIN_LA_TOMATINA) {
          eventManager.emit(EventTypes.LATOMATINA_END);
        }
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);

        eventManager.emit(EventTypes.SET_ALL_MULTIPLIER, false);

        // ！！！超重要！！！ ここでトラックフィーチャー、トマトフィーチャーで変更されたマスに変更する！
      } else {
        eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, settings.mode);
        if (setCurrentFreeSpinsTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
        } else {
          eventManager.emit(EventTypes.HIDE_WIN_LABEL);
        }
      }
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_DURATION / 2);
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private startFreeRoundsEndFade(settings: { outDuration: number; inDuration: number; callback: () => void }) {
    const animationChain: AnimationChain = new AnimationChain();

    const fadeOut = this.getFadeAnimation(1, settings.outDuration);
    const fadeIn = this.getFadeAnimation(0, settings.inDuration);

    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);

    fadeIn.addOnStart(() => {
      settings.callback();
    });

    fadeIn.addOnComplete(() => {
      this.interactive = false;
    });

    animationChain.start();

    this.interactive = true;
  }
}

export default FadeArea;
