import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { MAPPED_SYMBOLS } from '../../config/textures';
import { IUserBalance } from '../../global';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { countCoins, getCombosDataFromIcon } from '../../utils';
import { getNonNullableValue } from '../../utils/helper/helper';
import Resources from '../../utils/resources';

import type { IPayTableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number): number => {
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPayTableData[] = useMemo(() => {
    return config.payTableData.flatMap((slotId) => {
      const icon = getNonNullableValue(setSlotConfig()).icons.find((icon) => icon.id === slotId);
      return {
        slug: MAPPED_SYMBOLS[slotId],
        combos: icon ? getCombosDataFromIcon(icon) : [],
      };
    });
  }, []);

  const { currency } = balance;

  return (
    <section>
      <h1 className={styles.title}>{t('infoTabPayTable')}</h1>
      <div className={`${styles['paytable-list']} ${styles['wild']}`}>
        {data.map(
          ({ combos, slug }) =>
            (slug === ResourceTypes.symbolScatter || slug === ResourceTypes.symbolWild) && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <div className={styles['image__title']}>{t(`infoPayTable.${slug}`)}</div>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  </div>
                  <table className={styles['content']}>
                    <tbody>
                      {combos.map((i) => (
                        <tr key={i.pattern}>
                          <td>
                            <span className={styles.multiplier} data-combo={i.pattern}>
                              {i.pattern}{' '}
                            </span>
                            {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <div className={styles['paytable-list']}>
        {data.map(
          ({ combos, slug }) =>
            (slug === ResourceTypes.symbolWayArrowRight ||
              slug === ResourceTypes.symbolWayArrowLeft ||
              slug === ResourceTypes.symbolWayArrowUp ||
              slug === ResourceTypes.symbolWayArrowDown) && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  </div>
                  <table className={styles['content']}>
                    <tbody>
                      {combos.map((i) => (
                        <tr key={i.pattern}>
                          <td>
                            <span className={styles.multiplier} data-combo={i.pattern}>
                              {i.pattern}{' '}
                            </span>
                            {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
      <h1 className={styles.arrow_title}>{t('infoPayTable_symbolArrow')}</h1>
      <div className={styles['paytable-list']}>
        {data.map(
          ({ combos, slug }) =>
            slug != ResourceTypes.symbolScatter &&
            slug != ResourceTypes.symbolWild &&
            slug != ResourceTypes.symbolWayArrowRight &&
            slug != ResourceTypes.symbolWayArrowLeft &&
            slug != ResourceTypes.symbolWayArrowUp &&
            slug != ResourceTypes.symbolWayArrowDown && (
              <React.Fragment key={slug}>
                <div className={styles['paytable-list__item']}>
                  <div className={styles['img']}>
                    <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                    {i18n.exists(`infoPayTable.${slug}`) && (
                      <p className={styles.caption}>{t(`infoPayTable.${slug}`, '')}</p>
                    )}
                  </div>
                  <table className={styles['content']}>
                    <tbody>
                      {combos.map((i) => (
                        <tr key={i.pattern}>
                          <td>
                            <span className={styles.multiplier} data-combo={i.pattern}>
                              {i.pattern}{' '}
                            </span>
                            {formatNumber({ currency, value: calcMultiplier(i.multiplier, betAmount) })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ),
        )}
      </div>
    </section>
  );
};

export default PaytableComponent;
